
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onMounted,
  ref,
} from "vue";
import {
  userInvoiceInfo,
  updateUserPassword,
  getLogout,
  getUserInfoAll,
  saveUserInfoAll,
  userInfoExamine
} from "@/api/index.js";
import { ElMessage, ElMessageBox } from "element-plus";
import store from "@/store";
import { getToken, removeToken, getUid, removeUid } from "@/utils/cookies.js";
import router from "@/router";
import type {
  FormInstance,
  FormRules,
  UploadProps,
  UploadUserFile,
  Action,
} from "element-plus";
import { Plus } from "@element-plus/icons-vue";

export default defineComponent({
  setup() {
    interface props {
      userInfo: {
        name: string;
        logo: string;
        level: string;
        legal_person_name: string;
        legal_person_tel: string;
        person_charge_name: string;
        person_charge_tel: string;
        contacts_name: string;
        contacts_tel: string;
        website: string;
        email: string;
        duty_paragraph: string;
        bank: string;
        bank_number: string;
        landline: string;
        area_info: string;
        mailing_area: string;
        team_style: string;
        honors_qualifications: string;
        info: string;
        business: string;
      };
      form: {
        password: string;
        new_password: string;
        confirm_password: string;
      };
    }
    let state: props = reactive({
      userInfo: {
        name: "",
        logo: "",
        level: "",
        legal_person_name: "",
        legal_person_tel: "",
        person_charge_name: "",
        person_charge_tel: "",
        contacts_name: "",
        contacts_tel: "",
        website: "",
        email: "",
        duty_paragraph: "",
        bank: "",
        bank_number: "",
        landline: "",
        area_info: "",
        mailing_area: "",
        team_style: "",
        honors_qualifications: "",
        info: "",
        business: "",
      },
      form: {
        password: "",
        new_password: "",
        confirm_password: "",
      },
    });
    const ruleFormRef = ref<FormInstance>();
    const levels = [
      { name: "常务理事", level: "3" },
      { name: "理事单位", level: "2" },
      { name: "会员单位", level: "1" },
    ];

    const rules = reactive<FormRules>({
      legal_person_name: [
        {
          required: true,
          message: "请输入法定代表人",
          trigger: "blur",
        },
      ],
      person_charge_name: [
        {
          required: true,
          message: "请输入主管负责人",
          trigger: "blur",
        },
      ],
      contacts_name: [
        {
          required: true,
          message: "请输入联系人",
          trigger: "blur",
        },
      ],
      duty_paragraph: [
        {
          required: true,
          message: "请输入统一社会信用代码",
          trigger: "blur",
        },
      ],
      bank: [
        {
          required: true,
          message: "请输入开户银行",
          trigger: "blur",
        },
      ],
      bank_number: [
        {
          required: true,
          message: "请输入开户银行账号",
          trigger: "blur",
        },
      ],
      landline: [
        {
          required: true,
          message: "请输入联系电话",
          trigger: "blur",
        },
      ],
      area_info: [
        {
          required: true,
          message: "请输入注册地址",
          trigger: "blur",
        },
      ],
      mailing_area: [
        {
          required: true,
          message: "请输入邮寄地址",
          trigger: "blur",
        },
      ],
      logo: [
        {
          required: true,
          message: "请上传公司logo",
          trigger: "change",
        },
      ],
      info: [
        {
          required: true,
          message: "请输入企业简介",
          trigger: "blur",
        },
      ],
      business: [
        {
          required: true,
          message: "请输入业务范围",
          trigger: "blur",
        },
      ],
    });

    const levelName = computed(() => {
      let name = "";
      levels.map((r) => {
        if (r.level == state.userInfo.level) {
          name = r.name;
        }
      });
      return name;
    });

    const team_style_arr = ref<UploadUserFile[]>([]);
    const honors_qualifications_arr = ref<UploadUserFile[]>([]);

    onMounted(() => {
      if (getToken() && getUid()) {
        const params = {
          uid: parseInt(getUid()),
          tokenid: getToken(),
        };
        userInfoAll(params);
      } else {
        router.push({ path: "home" });
      }
    });

    // 获取个人信息
    const userInfoAll = (params: any) => {
      getUserInfoAll(params).then((res: any) => {
        if (res.code == "0") {
          const userInfo = {
            ...res.data.user,
            ...res.data.user_info,
            ...res.data.user_mailing_info,
          };
          team_style_arr.value =
            userInfo.team_style?.split(";").map((r: any, index: Number) => {
              return {
                name: "name" + index,
                url: r,
              };
            }) || [];
          honors_qualifications_arr.value =
            userInfo.honors_qualifications
              ?.split(";")
              .map((r: any, index: Number) => {
                return {
                  name: "name" + index,
                  url: r,
                };
              }) || [];
          store.commit("setUserInfo", userInfo);
          state.userInfo = userInfo;
        } else {
          removeToken();
          removeUid();
        }
      });
    };

    // 发票信息
    const open = (content: string) => {
      ElMessageBox.alert(content, "发票信息", {
        confirmButtonText: "确认",
      });
    };
    const InvoiceInfo = () => {
      const params = {
        uid: getUid(),
        tokenid: getToken(),
      };
      userInvoiceInfo(params).then((res: any) => {
        if (res.code == "0") {
          open(res.data.invoice_info);
        }
      });
    };

    // 修改密码
    const rulePasswordFormRef = ref<FormInstance>();
    const dialogPasswordVisible = ref(false);
    const validatePass = (rule: any, value: any, callback: any) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (state.form.confirm_password !== "") {
          if (!rulePasswordFormRef.value) return;
          rulePasswordFormRef.value.validateField(
            "confirm_password",
            () => null
          );
        }
        callback();
      }
    };
    const validatePass2 = (rule: any, value: any, callback: any) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== state.form.new_password) {
        callback(new Error("密码不一致！"));
      } else {
        callback();
      }
    };
    const passwordRules = reactive<FormRules>({
      password: [
        {
          required: true,
          message: "请输入旧密码",
          trigger: "blur",
        },
      ],
      new_password: [
        {
          required: true,
          message: "请输入新密码",
          trigger: "blur",
        },
        { validator: validatePass, trigger: "blur" },
      ],
      confirm_password: [
        {
          required: true,
          message: "请再次输入新密码",
          trigger: "blur",
        },
        { validator: validatePass2, trigger: "blur" },
      ],
    });
    const openPassword = (formEl: FormInstance | undefined) => {
      dialogPasswordVisible.value = true;
      if (!formEl) return;
      formEl?.resetFields();
    };
    const handlePasswordClose = () => {
      dialogPasswordVisible.value = false;
    };
    const savePassword = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          const params = {
            uid: getUid(),
            tokenid: getToken(),
            password: state.form.password,
            new_password: state.form.new_password,
          };
          updateUserPassword(params).then((res: any) => {
            if (res.code == "0") {
              ElMessage({
                message: "修改成功！",
                type: "success",
              });
              handlePasswordClose();
            }
          });
        }
      });
    };

    // 退出
    const logout = () => {
      ElMessageBox.confirm("请确认退出？", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const params = {
            uid: getUid(),
            tokenid: getToken(),
          };
          getLogout(params).then((res: any) => {
            if (res.code == "0") {
              ElMessage({
                message: "退出成功！",
                type: "success",
              });
              removeToken();
              removeUid();
              store.commit("setUserInfo", {});
              store.commit("setUserInfoAll", {});
              router.push({ path: "home" });
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "取消",
          });
        });
    };

    // 头像logo上传成功
    const handleAvatarSuccess: UploadProps["onSuccess"] = (res, uploadFile) => {
      if (res.code == 0) {
        state.userInfo.logo = res.data.file.path_url;
      }
    };

    const beforeAvatarUpload: UploadProps["beforeUpload"] = (rawFile) => {
      // if (rawFile.type !== "image/jpeg") {
      //   ElMessage.error("Avatar picture must be JPG format!");
      //   return false;
      // } else
      if (rawFile.size / 1024 > 500) {
        ElMessage.error("每张图片不超过500k!");
        return false;
      }
      return true;
    };

    // 团队风采上传成功
    const handleTeamSuccess: UploadProps["onSuccess"] = (res, uploadFile) => {
      if (res.code == 0) {
        team_style_arr.value.push({
          name: res.data.file.name,
          url: res.data.file.path_url,
        });
      }
    };

    const beforeTeamUpload: UploadProps["beforeUpload"] = (rawFile) => {
      if (rawFile.size / 1024 > 500) {
        ElMessage.error("每张图片不超过500k!");
        return false;
      }
      return true;
    };

    // 荣誉资质上传成功
    const handleHonorsSuccess: UploadProps["onSuccess"] = (res, uploadFile) => {
      if (res.code == 0) {
        honors_qualifications_arr.value.push({
          name: res.data.file.name,
          url: res.data.file.path_url,
        });
      }
    };

    const beforeHonorsUpload: UploadProps["beforeUpload"] = (rawFile) => {
      if (rawFile.size / 1024 > 500) {
        ElMessage.error("每张图片不超过500k!");
        return false;
      }
      return true;
    };

    // 删除图片
    const handleRemove: UploadProps["onRemove"] = (uploadFile, uploadFiles) => {
      console.log(uploadFile, uploadFiles);
    };

    // 图片预览
    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const handlePictureCardPreview: UploadProps["onPreview"] = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url!;
      dialogVisible.value = true;
    };

    // 保存
    const save = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          const params = {
            uid: getUid(),
            tokenid: getToken(),
            ...state.userInfo,
            team_style:
              team_style_arr.value?.map((r) => r.url).join(";") || null,
            honors_qualifications:
              honors_qualifications_arr.value?.map((r) => r.url).join(";") ||
              null,
          };
          saveUserInfoAll(params).then((res: any) => {
            if (res.code == "0") {
              ElMessage({
                message: "保存成功！",
                type: "success",
              });
              setTimeout(() => {
                location.reload();
              }, 2000);
            }
          });
        }
      });
    };

    // 提交
    const submit = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          const params = {
            uid: getUid(),
            tokenid: getToken(),
            ...state.userInfo,
            team_style:
              team_style_arr.value?.map((r) => r.url).join(";") || null,
            honors_qualifications:
              honors_qualifications_arr.value?.map((r) => r.url).join(";") ||
              null,
          };
          userInfoExamine(params).then((res: any) => {
            if (res.code == "0") {
              ElMessage({
                message: "提交成功！",
                type: "success",
              });
              setTimeout(() => {
                location.reload();
              }, 2000);
            }
          });
        }
      });
    };

    return {
      ...toRefs(state),
      Plus,
      levelName,
      rules,
      ruleFormRef,
      dialogImageUrl,
      dialogVisible,
      team_style_arr,
      honors_qualifications_arr,
      InvoiceInfo,
      rulePasswordFormRef,
      dialogPasswordVisible,
      passwordRules,
      openPassword,
      handlePasswordClose,
      savePassword,
      logout,
      handleAvatarSuccess,
      beforeAvatarUpload,
      handleTeamSuccess,
      beforeTeamUpload,
      handleHonorsSuccess,
      beforeHonorsUpload,
      handleRemove,
      handlePictureCardPreview,
      save,
      submit,
    };
  },
  components: { Plus },
});
